import React, { useState, useEffect, ChangeEvent } from 'react';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  List,
  Typography,
  Box,
  Autocomplete,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { hasErrorRequired } from 'services/logic/formValidation';
import sectionsForCustomInputs from 'utils/sectionsForCustomInputs';
import PermissionsWrapper from 'sharedComponents/PermissionsWrapper';
import { UserTypes } from 'lg-helpers/dist/constants/user/UserTypes';
import { selectAuthUser } from 'modules/auth/storeSliceAuth';
import { useSelector } from 'react-redux';
import { UserTypesArray } from 'lg-helpers/dist/constants/user/UserTypes';
import ErrorMessages from 'services/constants/errorMessages';
import {
  ICustomInputDefinitions,
  ICustomInputListItem,
} from 'lg-helpers/dist/shared/interfaces/ICustomInputDefinitions';
import ListItemDefinition from './ListItemDefinition';
const { isRequired } = ErrorMessages;

interface CustomInputListDefinitionProps {
  inputDefinition: ICustomInputDefinitions;
  setInputDefinition: (inputDefinition: ICustomInputDefinitions) => void;
  canValidate: boolean;
  setCanValidate: (canValidate: boolean) => void;
  hideCallback: (inputDefinition: ICustomInputDefinitions) => void;
  userCanEdit?: boolean;
}

const CustomInputListDefinition = ({
  inputDefinition,
  setInputDefinition,
  canValidate,
  userCanEdit = true,
  setCanValidate,
  hideCallback,
}: CustomInputListDefinitionProps) => {
  const authUser = useSelector(selectAuthUser);
  const [isUserSuperAdmin, setIsUserSuperAdmin] = useState(false);
  const [localInputDefinition, setLocalInputDefinition] = useState<ICustomInputDefinitions>({
    ...inputDefinition,
    showOnQRCodeScreen: inputDefinition.showOnQRCodeScreen || false, // Ensure default value
  });
  const [commaSeparatedList, setCommaSeparatedList] = useState('');

  useEffect(() => {
    if (authUser?.type === UserTypes.admin.super) {
      setIsUserSuperAdmin(true);
    }
  }, [authUser]);

  const updateExternalInputDefinition = (newValue: ICustomInputDefinitions) => {
    let hasError =
      hasErrorRequired(newValue.label) ||
      hasErrorRequired(newValue.propertyPathInManifestCustomInputs);
    if (newValue.items && newValue.items.length) {
      newValue.items.forEach(item => {
        hasError = hasError || hasErrorRequired(item.label) || hasErrorRequired(item.value);
      });
    }

    setInputDefinition({
      ...newValue,
      propertyPathInManifestCustomInputs: (
        newValue.propertyPathInManifestCustomInputs || ''
      ).trim(),
      isValidCustomInputDefinition: !hasError,
    });
  };

  const handleChange = (event: any, callUpdateExternal: boolean) => {
    const target = event.target as HTMLInputElement;
    const changedLocalInputDefinition: ICustomInputDefinitions = {
      ...localInputDefinition,
      [target.name]: target?.type === 'checkbox' ? target.checked : target.value || '',
    };

    console.log('changedLocalInputDefinition: ', callUpdateExternal, changedLocalInputDefinition);

    setLocalInputDefinition(changedLocalInputDefinition);
    if (callUpdateExternal) {
      updateExternalInputDefinition(changedLocalInputDefinition);
    }
    setCanValidate(false);
  };

  const handleNativeSelectChange = (name: string, value: any) => {
    handleChange({ target: { name, value } }, true);
  };

  const handleBlur = () => updateExternalInputDefinition(localInputDefinition);

  const handleCommaSeparatedList = () => {
    const parsedList: ICustomInputListItem[] = commaSeparatedList
      .split(',')
      .filter((e: any) => e)
      .map((e: string) => {
        const keyValPair = e.trim().split(':');
        return {
          id: uuidv4(),
          label: keyValPair[0].trim(),
          value: keyValPair[1]?.trim() ?? keyValPair[0].trim(),
        };
      });
    const currentList =
      localInputDefinition?.items?.length && (localInputDefinition?.items[0] || {})?.label
        ? localInputDefinition.items
        : [];
    localInputDefinition.items = [...currentList, ...(parsedList || [])];
    setCommaSeparatedList('');
  };

  const setListItem = (newItem: ICustomInputListItem) => {
    let newItems;

    if (localInputDefinition.items && localInputDefinition.items.length > 0) {
      newItems = localInputDefinition.items.map(oldItem => {
        if (oldItem.id === newItem.id) {
          return newItem;
        }
        return oldItem;
      });
    }

    const changedLocalInputDefinition: ICustomInputDefinitions = {
      ...localInputDefinition,
      items: newItems,
    };
    setLocalInputDefinition(changedLocalInputDefinition);
    updateExternalInputDefinition(changedLocalInputDefinition);
  };

  const removeListItem = (id: string) => {
    const newItems = localInputDefinition?.items?.filter(item => item.id !== id);
    const changedLocalInputDefinition = {
      ...localInputDefinition,
      items: newItems,
    };
    setLocalInputDefinition(changedLocalInputDefinition);
    updateExternalInputDefinition(changedLocalInputDefinition);
  };

  const addListItem = () => {
    const newItem = {
      id: uuidv4(),
    };
    let newItems;
    if (localInputDefinition.items) {
      newItems = [...localInputDefinition.items, newItem];
    } else {
      newItems = [newItem];
    }
    const changedLocalInputDefinition: ICustomInputDefinitions = {
      ...localInputDefinition,
      items: newItems as { id: string; label: string; value: string }[],
    };
    setLocalInputDefinition(changedLocalInputDefinition);
    updateExternalInputDefinition(changedLocalInputDefinition);
  };

  useEffect(() => setLocalInputDefinition(inputDefinition), [inputDefinition]);

  useEffect(() => {
    if (!localInputDefinition) {
      setLocalInputDefinition({
        ...inputDefinition,
        showOnQRCodeScreen: inputDefinition.showOnQRCodeScreen || false, // Ensure default value
      });
      return;
    }

    if (!localInputDefinition?.items || localInputDefinition?.items?.length === 0) {
      addListItem();
    }
    // eslint-disable-next-line
  }, [localInputDefinition]);

  return (
    <Card style={{ width: '100%' }}>
      <CardHeader
        title={`${localInputDefinition.order}.[${localInputDefinition.type}] ${
          localInputDefinition.label || 'New Custom Input Definition'
        }`}
        action={
          <PermissionsWrapper canAccess={[UserTypes.admin.super]}>
            <FormControlLabel
              control={
                <Switch
                  checked={!!localInputDefinition.isRequired}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="isRequired"
                  color="primary"
                  disabled={!userCanEdit}
                />
              }
              label="Is Required"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={!!localInputDefinition.showOnQRCodeScreen}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="showOnQRCodeScreen"
                  color="primary"
                  disabled={!userCanEdit}
                />
              }
              label="Show on QR Code Screen"
            />
            <Button
              variant="outlined"
              disabled={!userCanEdit}
              onClick={() => hideCallback(inputDefinition)}
            >
              {inputDefinition.isHidden ? (
                <>
                  <VisibilityOffIcon color="secondary" />
                  <Box marginLeft={1}>
                    <Typography color="secondary">Is hidden</Typography>
                  </Box>
                </>
              ) : (
                <>
                  <VisibilityIcon />
                  <Box marginLeft={1}>
                    <Typography>Is visible</Typography>
                  </Box>
                </>
              )}
            </Button>
          </PermissionsWrapper>
        }
      />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item sm={6} xs={12}>
            <TextField
              fullWidth
              label="Label"
              name="label"
              disabled={!isUserSuperAdmin || !userCanEdit}
              onChange={(evt: ChangeEvent<HTMLInputElement>) => handleChange(evt, true)}
              onBlur={handleBlur}
              value={localInputDefinition.label || ''}
              variant="outlined"
              error={hasErrorRequired(localInputDefinition.label, canValidate)}
              helperText={
                hasErrorRequired(localInputDefinition.label, canValidate) ? isRequired : ''
              }
              required
            />
          </Grid>
          <PermissionsWrapper canAccess={[UserTypes.admin.super]}>
            <Grid item sm={6} xs={12}>
              <TextField
                fullWidth
                label={`Property Path in #.customInputs.${
                  localInputDefinition.propertyPathInManifestCustomInputs || '?'
                }`}
                name="propertyPathInManifestCustomInputs"
                onChange={(evt: ChangeEvent<HTMLInputElement>) => handleChange(evt, true)}
                onBlur={handleBlur}
                value={localInputDefinition.propertyPathInManifestCustomInputs || ''}
                variant="outlined"
                error={hasErrorRequired(
                  localInputDefinition.propertyPathInManifestCustomInputs,
                  canValidate
                )}
                helperText={
                  hasErrorRequired(
                    localInputDefinition.propertyPathInManifestCustomInputs,
                    canValidate
                  )
                    ? isRequired
                    : ''
                }
                required
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                disabled={true}
                fullWidth
                label="Column index"
                name="columnIndex"
                value={localInputDefinition.columnIndex || ''}
                variant="outlined"
              />
            </Grid>
            <Grid item sm={6} xs={12} />
          </PermissionsWrapper>
          <PermissionsWrapper canAccess={[UserTypes.admin.super]}>
            <Grid item sm={6} xs={12}>
              <Autocomplete
                multiple
                options={UserTypesArray}
                isOptionEqualToValue={(option, val) => option === val}
                value={localInputDefinition.userTypesCanEdit || []}
                id="userTypesCanEdit"
                onChange={(event, value) => handleNativeSelectChange('userTypesCanEdit', value)}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="User Types - Edit"
                    placeholder="Select user types"
                  />
                )}
              />
            </Grid>
          </PermissionsWrapper>
          <PermissionsWrapper canAccess={[UserTypes.admin.super]}>
            <Grid item sm={6} xs={12}>
              <Autocomplete
                multiple
                getOptionDisabled={() => false}
                options={sectionsForCustomInputs}
                value={localInputDefinition.sectionsCanEdit || []}
                isOptionEqualToValue={(option, val) => option === val}
                onChange={(event, value) => handleNativeSelectChange('sectionsCanEdit', value)}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Edit in Sections"
                    placeholder="Select sections"
                  />
                )}
              />
            </Grid>
          </PermissionsWrapper>
          <PermissionsWrapper canAccess={[UserTypes.admin.super]}>
            <Grid item sm={6} xs={12}>
              <Autocomplete
                multiple
                options={UserTypesArray}
                value={localInputDefinition.userTypesCanOnlyRead || []}
                isOptionEqualToValue={(option, val) => option === val}
                onChange={(event, value) => handleNativeSelectChange('userTypesCanOnlyRead', value)}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="User Types - Only Read"
                    placeholder="Select user types"
                  />
                )}
              />
            </Grid>
          </PermissionsWrapper>
          <PermissionsWrapper canAccess={[UserTypes.admin.super]}>
            <Grid item sm={6} xs={12}>
              <Autocomplete
                multiple
                getOptionDisabled={() => false}
                options={sectionsForCustomInputs}
                value={localInputDefinition.sectionsCanOnlyRead || []}
                isOptionEqualToValue={(option, val) => option === val}
                onChange={(event, value) => handleNativeSelectChange('sectionsCanOnlyRead', value)}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Sections Only Read"
                    placeholder="Select sections"
                  />
                )}
              />
            </Grid>
          </PermissionsWrapper>
          <Grid item sm={6} xs={12}>
            <Autocomplete
              options={localInputDefinition?.items?.filter(item => item.label && item.value) || []}
              value={localInputDefinition?.items?.find(
                item => item.value === localInputDefinition.defaultValue
              )}
              isOptionEqualToValue={(option, value) => (value ? option.id === value.id : false)}
              getOptionLabel={option => option.label}
              onChange={(event, value) => handleNativeSelectChange('defaultValue', value?.value)}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Default Value"
                  required
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />
          </Grid>
        </Grid>
        <Box marginTop={3}>
          <Typography>Items</Typography>
          <Grid container columnSpacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={12} md={9}>
              <TextField
                fullWidth
                label="Comma Seperated List for Label and Value"
                value={commaSeparatedList}
                onChange={(event: any) => setCommaSeparatedList(event.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={handleCommaSeparatedList}
              >
                Add Label and Value
              </Button>
            </Grid>
          </Grid>
          <List>
            {localInputDefinition.items &&
              localInputDefinition?.items?.length > 0 &&
              localInputDefinition?.items?.sort((a, b) => {
                if (a.label == null) return 1;
                if (b.label == null) return -1;
                return a.label.localeCompare(b.label);
              })
              .map((item, index) => {
                return (
                  <ListItemDefinition
                    key={item.id}
                    listItem={item}
                    showRemoveButton={localInputDefinition?.items?.length !== 1}
                    showAddButton={(localInputDefinition?.items || [])?.length - 1 === index}
                    onSetListItem={setListItem}
                    onAddListItem={addListItem}
                    onRemoveListItem={removeListItem}
                    canValidate={canValidate}
                    userCanEdit={userCanEdit}
                  />
                );
              })}
          </List>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CustomInputListDefinition;
